import { graphql } from "gatsby"
import React, { Fragment } from "react"
import styled from "styled-components"

import { Section } from "../components/elements"
import Layout from "../components/layouts/Layout"
import {
  ButtonLink,
  Col,
  Color,
  Grid,
  Media,
  Wrapper,
} from "../components/utilities"

import licensed from "../images/licensed.png"
import upfront from "../images/upfront.png"

const HeaderTitle = styled.div`
  padding-top: 2rem;

  h1 {
    color: ${Color.white};
    margin: 0;
    line-height: 150%;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
  }
`

const HeaderGrid = styled(Grid)`
  padding-top: 2rem;
  min-height: 400px;

  ${ButtonLink} {
    text-decoration: none;
    margin: 0;
  }
`

const Row = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  box-sizing: border-box;
  height: 100%;
  padding-bottom: 3rem;

  img {
    height: 90px;
  }

  ${Media.below.tablet`
    display: none;
  `}
`

const TitleGrid = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr;
  height: 100%;
`

const CenterBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
`

const HeaderRender = ({ node }) => (
  <Wrapper>
    <HeaderGrid modifiers={["gap"]}>
      <HeaderTitle>
        <TitleGrid>
          <CenterBox>
            <h1 dangerouslySetInnerHTML={{ __html: node.title }} />
          </CenterBox>
          <CenterBox>
            <ButtonLink to="/contact/" modifiers={"large"}>
              Click Here To Request Service
            </ButtonLink>
          </CenterBox>
        </TitleGrid>
      </HeaderTitle>
      <Col>
        <Row>
          <img src={licensed} alt="Fully Licensed" />
          <img src={upfront} alt="Upfront Pricing" />
        </Row>
      </Col>
    </HeaderGrid>
  </Wrapper>
)

const InternalPage = ({ data }) => {
  const node = data.allWordpressPage.edges[0].node
  return (
    <Layout
      title={node.acf.seo_title}
      description={node.acf.seo_description}
      image={node.featured_media ? node.featured_media.source_url : null}
      mobileBackground={node.acf.use_mobile_color_background}
      bodyRender={() => <HeaderRender node={node} />}
    >
      {() => (
        <Fragment>
          <Section modifiers={"danger"}>
            <div dangerouslySetInnerHTML={{ __html: node.content }}></div>
          </Section>
        </Fragment>
      )}
    </Layout>
  )
}

export default InternalPage

export const internalQuery = graphql`
  query InternalPageQuery($id: String!) {
    allWordpressPage(filter: { id: { eq: $id } }) {
      edges {
        node {
          id
          title
          content
          path
          featured_media {
            source_url
          }
          acf {
            seo_title
            seo_description
            use_mobile_color_background
          }
        }
      }
    }
  }
`
